/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example 
 */

import { withStyles } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, AccordionGroup  } from 'diginet-core-ui/components';
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Approvals from "../../../approvals/approvals";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import W25F2001Education from "./W25F2001Education";
import W25F2001General from "./W25F2001General";

const styles = theme => ({
    heading: {
        textTransform: "uppercase",
        fontWeight: 500
    },
    ExpansionPanelSummary: {
        padding: 0,
        maxWidth: 300,
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "12px 0"
        },
        "&.Mui-expanded": {
            minHeight: "inherit"
        }
    },
});

class W25F2001 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            VoucherID: "",
        }
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2001", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({ VoucherID: "" });
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W25F2001",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb(data);
            }
        });
    };

    renderFormInfo = (data) => {
        return (
            <>
                <AccordionGroup>
                    <Accordion expand>
                        <AccordionSummary>{Config.lang("Thong_tin_chung")}</AccordionSummary>
                        <AccordionDetails>
                            <W25F2001General data={data} />
                        </AccordionDetails>
                    </Accordion>
                    {/* Hoc van chuyen mon */}
                    <Accordion>
                        <AccordionSummary>{Config.lang("Hoc_van_va_chuyen_mon")}</AccordionSummary>
                        <AccordionDetails>
                            <W25F2001Education data={data} />
                        </AccordionDetails>
                    </Accordion>
                </AccordionGroup>
            </>
        );
    };

    render() {
        const { iPermission, dataForm, VoucherID } = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Duyet_de_xuat_tuyen_dung")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                parentProps={this.props}
                                FormID={"W25F2001"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                singleClick
                                formInfoRender={(e) => this.renderFormInfo(e)}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
), withStyles(styles, { withTheme: true }))(W25F2001);
