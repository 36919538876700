/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/19/2020
 * @Example 
 */

import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup}                   from "react-bootstrap";
import Config                        from "../../../../config";
import {withStyles}                  from "@material-ui/core";
import {Column}                      from "devextreme-react/data-grid";
import GridContainer                 from "../../../grid-container/grid-container";
import IconButton                    from "@material-ui/core/IconButton";
import Icons                         from "../../../common/icons";
import ButtonGeneral                 from "../../../common/button/button-general";
import W25F2011AddCriteria           from "./tab2/W25F2011AddCriteria";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../redux/W2X/W25F2011/W25F2011_actions";

const styles = {
};
class W25F2011Criteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            showAddCriteria: false,
            rowData: null,
            mode: "add"
        };
        this.filter = {};
        this.renderAddCriteria = false;
    }

    loadForm = () => {
        const { loadForm } = this.props;
        if (loadForm) {
            loadForm(1, (error, data) => {
                this.setState({
                    dataGrid: data ? data : []
                })
            });
        }
    };

    componentDidMount () {
        this.loadForm();
    }

    toggleCriteria = (mode, data = null, isUpdate) => {
        this.renderAddCriteria = true;
        this.setState({
            showAddCriteria: !!mode,
            mode: mode ? mode : "",
            rowData: data
        }, () => {
            if (isUpdate) {
                this.loadForm();
            }
        });
    };

    deleteCriteria = (e) => {
        const {data} = e;
        const {data: dataForm} = this.props;

        const params = {
            FormID: "W25F2011",
            FieldName: "W25F2011",
            RecInfoID: dataForm && dataForm.RecInfoID ? dataForm.RecInfoID : "",
            Mode: 1,
            Language: Config.language || "84",
            EvaluationElementID: data && data.EvaluationElementID ? data.EvaluationElementID : ""
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"),
            () => {
                this.setState({loading: true});
                this.props.W25F2011Actions.deleteCriteria(params, (error, data) => {
                    this.setState({loading: false});
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (data) {
                        if (data.Status === 1) {
                            Config.popup.show("INFO", data.Message);
                        } else {
                            Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                            this.loadForm();
                        }
                    }
                });
            });
    };

    renderButtonAction = e => {
        const {disabled} = this.props;
        const {data} = e;
        return (
            <div className="">
                <IconButton
                    aria-label="view"
                    disabled={disabled}
                    style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                    onClick={() => this.toggleCriteria("edit", data)}
                >
                    <Icons name={"edit"} />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    disabled={disabled}
                    style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                    onClick={() => this.deleteCriteria(e)}
                >
                    <Icons name={"delete"} />
                </IconButton>
            </div>
        );
    };

    render() {
        const {data, theme, disabled} = this.props;
        const { mode: modeAdd, rowData, dataGrid, showAddCriteria } = this.state;
        const listEvaluationElementID = dataGrid.map(d => d.EvaluationElementID);

        return (
            <div style={{width: '100%'}}>
                {(showAddCriteria || this.renderAddCriteria) && <W25F2011AddCriteria
                    mode={modeAdd}
                    rowData={rowData}
                    dataForm={data}
                    open={showAddCriteria}
                    listEvaluationElementID={listEvaluationElementID}
                    onClose={this.toggleCriteria}
                />}
                <FormGroup>
                    <GridContainer
                        dataSource={dataGrid}
                        keyExpr={"EvaluationElementID"}
                        totalItems={0}
                        itemPerPage={this.filter.limit}
                        skipPerPage={this.filter.skip}
                        onChangePage={this.handleChangePage}
                        onChangePerPage={this.handleChangePerPage}
                        pagerFullScreen={false}
                        showBorders={false}
                        typePaging={"remote"}
                        loading={false}
                        grouping={{
                            allowCollapsing: false,
                            expandMode: "rowClick",
                        }}
                        rowAlternationEnabled={false}
                        showRowLines={false}
                    >
                        <Column
                            dataField={'AppCriterionGroupID'}
                            groupIndex={0}
                            fixed={true}
                            allowFixing={true}
                            allowGrouping={true}
                            groupCellTemplate={(cellElement, cellInfo) => {
                                const {data} = cellInfo;
                                const item = data.items && data.items.length > 0 ? data.items[0] : null;
                                const name = item && item.AppCriterionGroupName ? item.AppCriterionGroupName : cellInfo.displayValue;
                                return cellElement.innerHTML = `<span style="color: ${theme.palette.info.main}">${name ? name : Config.lang("Khong_ten")}</span>`;
                            }}
                        />
                        <Column
                            dataField={'AppCriterionGroupName'}
                            visible={false}
                        />
                        <Column
                            caption={Config.lang("Tieu_chi")}
                            dataField={"EvaluationElementName"}
                        />
                        <Column
                            caption={Config.lang("Diem_ky_vong")}
                            dataField={"ExpectationPoint"}
                        />
                        <Column
                            caption={Config.lang("Trong_so")}
                            dataField={"Proportion"}
                        />
                        <Column
                            alignment={"center"}
                            caption={""}
                            dataField={"RelationName"}
                            cellRender={this.renderButtonAction}
                        />
                    </GridContainer>
                </FormGroup>
                <FormGroup>
                    <ButtonGeneral
                        name={Config.lang("Them_tieu_chi")}
                        typeButton={"add"}
                        variant={"outlined"}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        disabled={disabled}
                        onClick={() => this.toggleCriteria("add")}
                    />
                </FormGroup>
            </div>
        );
    }

}

W25F2011Criteria.propTypes = {
    data: PropTypes.object
};

export default compose(connect((state) => ({
        iPermission: state.general.iPermission,
    }),
    (dispatch) => ({
        W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(W25F2011Criteria);
