import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import Config from "../../../../config";
import UserImage from "../../../common/user/user-image";
import {Card, CardContent, CardHeader, FormGroup, Icon, IconButton, Typography, withStyles} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import {bindActionCreators, compose} from "redux";
import _ from "lodash";
import moment from "moment";
import Icons from "../../../common/icons";
import Modal from "../../../common/modal/modal";
import {Combo} from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import DataSource from "devextreme/data/data_source";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";
import * as generalActions from "../../../../redux/general/general_actions";

const styles = (theme) => {
    return ({
        flexDRow: {
            display: "flex",
            alignItems: "center"
        },
        flexDColumn: {
            display: "flex",
            flexDirection: "column",
        },
        primaryText: {
            color: theme.palette.primary.main,
            fontWeight: 600,
            textTransform: "uppercase",
        },
        infoText: {
            color: theme.palette.info.main,
            fontWeight: 400
        },
        iconWithLabel: {
            height: "auto",
            fontWeight: 400,
            margin: "5px 0px",
            "& .MuiIcon-root": {
                textAlign: "center",
                marginRight: 8,
                width: "1.3em",
                fontWeight: 400,
                fontSize: "1.313rem",
            },
            [theme.breakpoints.down("xs")]: {
                display: "flex",
                justifyContent: "space-between"
            },

        },
        cardHeaderCalendar: {
            backgroundColor: theme.palette.danger.main,
            padding: 10,
            "& .MuiCardHeader-title": {
                color: "#FFF",
                textAlign: "center",
                fontWeight: 600,
                fontSize: "1.6rem",
            },
        },
        rowCardContent: {
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        alignCenterMd: {
            [theme.breakpoints.up("md")]: {
                alignItems: "center",
            },
        },
        // colProfile: {
        //     // "@media (min-width:768px) and (max-width: 1024px)": {
        //     //     width: "73.33%",
        //     // },
        // },
        containerProfile: {
            display: "flex",
            alignItems: "center",
        },
        containerProfileW25F2023: {
            "@media (max-width: 1350px)": {
                flexDirection: "column"
            },
            "@media (max-width:980px)": {
                flexDirection: "row",
            },
        },
        colRightInfo: {
            "@media (min-width:460px) and (max-width:768px)": {
                width: "63.67%",
            },
            [theme.breakpoints.down("sm")]: {
                margin: "15px 0",
            },
        },
        colCalendarInfo: {
            "@media (min-width:768px) and (max-width: 992px)": {
                width: "26.33%",
            },
            "@media (min-width: 1500px)": {
                maxWidth: 250,
            },
        },
        calendarCard: {
            display: "flex",
            justifyContent: "flex-end",
        },
        cardCalendar: {
            width: "100%",
            "@media (min-width:1280px)": {
                width: "80%",
            },
        },
        cardContentCalendar: {
            "& .MuiTypography-root.MuiTypography-subtitle1": {
                fontWeight: 600,
                color: theme.palette.text.subtext,
            },
            "&:last-child": {
                paddingBottom: 14,
            },
            "@media (max-width:767px)": {
                flexDirection: "column",
                maxWidth: "100%",
            },
        },
        boxShadowCard: {
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        },
        boxShadow: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
        candidateName: {
            fontWeight: 600,
            textTransform: "uppercase",
            color: theme.palette.primary.main,
            "@media (min-width:300px) and (max-width: 600px)": {
                textAlign: "center",
            },
        },
        profile: {
            fontWeight: 400,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                margin: "15px 0"
            },
        },
        candidateAvatar: {
            marginRight: 8,
            "@media (max-width:1400px)": {
                marginRight: 0,
                marginBottom: 8,
            },
        },
        borderSide: {
            borderRight: "1px solid #9597A1",
            borderLeft: "1px solid #9597A1",
            [theme.breakpoints.down("sm")]: {
                borderRight: "none",
                borderLeft: "none",
                borderBottom: "1px solid #9597A1",
                borderTop: "1px solid #9597A1",
            },
        },
        otherInfo: {
            height: "100%",
            [theme.breakpoints.down("sm")]: {
                paddingLeft: 0,
            }
        },
        otherInfoTitle: {
            color: "#555768",
            fontWeight: 600,
            lineHeight: 2,
            textTransform: "uppercase",
        },
        modalActions: {
            display: "flex",
            justifyContent: "flex-end",
        },
        fillLoading: {
            fill: theme.palette.primary.main + "!important",
        },
        userImage: {
            width: 53,
            height: 53,
            "&:hover": {
                position: "relative"
            },
            "&:not(:hover) > button": {
                display: "none"
            }
        },
        iconRemoveInterviewer: {
            position: "absolute",
            top: -8,
            right: 0,
            "& .MuiIconButton-label": {
                border: "2px solid #FFF",
                borderRadius: "50%",
                "&:hover": {
                    transform: "scale(1.1)",
                    transition: "0.4s",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.35)",
                }
            },
        },
        skeletonInfoContent: {
            width: "60%",
            [theme.breakpoints.down("xs")]: {
                width: "100%"
            },
        },
        skeletonInfo: {
            width: "100%",
            [theme.breakpoints.down("xs")]: {
                width: "30%"
            },
        }
    })
};

class CandidateProFileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false,
            openEdit: false,
            userID: "",
            isInterviewListHover: false
        }
    }

    loadForm = (paramUpdated = {}) => {
        const {dataParam, formID} = this.props;
        const params = {
            FormID: _.get(dataParam, "FormID", formID),
            Language: Config.language || 84,
            CandidateID: _.get(dataParam, "CandidateID", ""),
            RecInfoID: _.get(dataParam, "RecInfoID", ""),
            ...paramUpdated,
        }
        this.props.generalActions.getCandidateProfile(params, (err) => {
            this.setState({loadingForm: false});
            if (err) Config.popup.show("ERROR", err);
        })
    }

    getDataField = (fieldName, otherFieldName = "") => {
        const {data, dataParam, getCandidateProfile} = this.props;
        if (_.isEmpty(dataParam)) return _.get(data, fieldName, otherFieldName ? _.get(data, otherFieldName, "") : "");
        if (_.isEmpty(data)) return _.get(getCandidateProfile, fieldName, "");
    }

    clsx = (...spread) => _.join(spread, " ");

    formatCurrency = (value, currency = "VND") => {
        if (!value) return "";
        let valueParsed = parseFloat(value);
        let languageCode = "en-US";
        let regexSeparator = /\,/g; //eslint-disable-line

        let charReplace = ".";
        let options = valueParsed % 1 === 0 ? {minimumFractionDigits: 0, maximumFractionDigits: 0} : {};
        if (currency === "VND") {
            languageCode = "vi-VN";
            regexSeparator = /\./g; //eslint-disable-line
            charReplace = ",";
            valueParsed = value;
        }

        const formatter = new Intl.NumberFormat(languageCode, {
            style: "currency",
            currency,
            ...options,
        });
        return formatter.format(valueParsed).replace(regexSeparator, charReplace);
    };

    formatDate = (date, format = "DD/MM/YYYY") => {
        const fmDate = moment.utc(date);
        let resDate = "";
        if (!fmDate.isValid()) return resDate;
        resDate = fmDate.format(format);
        if (format === "dddd") {
            switch (_.upperCase(resDate)) {
                case "MONDAY":
                    resDate = Config.lang("Thu_hai")
                    break;
                case "TUESDAY":
                    resDate = Config.lang("Thu_ba")
                    break;
                case "WEDNESDAY":
                    resDate = Config.lang("Thu_tu")
                    break;
                case "THURSDAY":
                    resDate = Config.lang("Thu_nam")
                    break;
                case "FRIDAY":
                    resDate = Config.lang("Thu_sau")
                    break;
                case "SATURDAY":
                    resDate = Config.lang("Thu_bay")
                    break;
                case "SUNDAY":
                    resDate = Config.lang("Chu_nhat")
                    break;
                default:
                    break;
            }
        }
        return resDate;
    }

    onDeleteInterviewer = (item) => {
        const {onChooseACurator} = this.props;
        Config.popup.show("YES_NO", _.join([Config.lang("Ban_muon_xoa"), `<b>${_.trim(_.get(item, "EmployeeName", "")) || Config.lang("nguoi_nay")}</b>`, Config.lang("ra_khoi_danh_sach")], " "), () => {
            onChooseACurator(_.get(item, "UserID", ""), "delete");
        })
    }

    onChangeValueCombo = e => {
        this.setState({
            userID: _.get(e, "value", "")
        })
    }

    onCloseModal = () => {
        this.setState({
            openEdit: false
        });
    };

    onOpenModal = () => {
        this.setState({
            openEdit: true
        });

    };

    renderCalendarInfo = (isLoading) => {
        const {classes, data} = this.props;
        const interviewDateTime = _.get(data, "InterviewDate", "");
        const interviewDate = this.formatDate(interviewDateTime);
        const interviewDay = this.formatDate(interviewDateTime, "dddd");
        const interviewTime = this.formatDate(interviewDateTime, "LT");
        if (!interviewDateTime) return "";
        return (
            <Col md={4} xs={12} className={classes.colCalendarInfo}>
                <div className={classes.calendarCard}>
                    <Card className={this.clsx(classes.boxShadow, classes.cardCalendar)} variant="outlined">
                        <CardHeader className={classes.cardHeaderCalendar}
                                    title={!isLoading ? interviewTime : interviewTime ?
                                        <Skeleton animation="wave"/> : ""}/>
                        <CardContent className={classes.cardContentCalendar}>
                            <Typography component="div" align="center">
                                <Typography align="center" variant="subtitle1">
                                    {!isLoading ? interviewDate : <Skeleton animation="wave"/>}
                                </Typography>
                                <Typography align="center" variant="subtitle1">
                                    {!isLoading ? (interviewDay ? `(${interviewDay})` : "") :
                                        interviewDay ? <Skeleton animation="wave"/> : ""}
                                </Typography>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Col>)
    }

    renderItemComboListUser = (e) => {
        const {classes} = this.props;
        const data = _.join(_.compact(
            [_.get(e, "UserID", false),
                _.get(e, "EmployeeName", false),
                _.get(e, "DutyName", false)
            ]), " • ");
        return (
            <div className={classes.flexDRow}>
                <UserImage data={e} width={32} height={32}/>
                <Typography className={classes.subText} variant="subtitle1">
                    {_.map(data, o => o)}
                </Typography>
            </div>)
    }

    renderComboListUser = () => {
        const {userID} = this.state;
        const listMemberFollow = _.get(this.props, "data.ListMemberFollow", "");
        const arrListMemberFollow = listMemberFollow.split(',');
        const getUserCache = _.filter(Config.listUsers, item => item.UserID && !_.includes(arrListMemberFollow, item.UserID));
        const comboData = new DataSource({
            store: getUserCache,
            paginate: true,
            pageSize: 50
        });
        return (
            <Combo
                dataSource={comboData}
                displayExpr={"EmployeeName"}
                valueExpr={"UserID"}
                stylingMode={"underlined"}
                value={userID}
                shrink={true}
                required={true}
                disabled={false}
                label={Config.lang("Nguoi_phu_trach")}
                onValueChanged={this.onChangeValueCombo}
                acceptCustomValue={true}
                itemRender={this.renderItemComboListUser}
            />)
    }

    renderModalEdit = () => {
        const {userID} = this.state;
        const {classes, onChooseACurator} = this.props;
        return (
            <>
                <Modal.Content>
                    <FormGroup>
                        {this.renderComboListUser()}
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions className={classes.modalActions}>
                    <ButtonGeneral
                        name={Config.lang("Huy")}
                        typeButton={"remove"}
                        style={{textTransform: "uppercase"}}
                        size={"medium"}
                        onClick={this.onCloseModal}
                    />
                    <ButtonGeneral
                        disabled={!Boolean(userID)}
                        name={Config.lang("Them")}
                        typeButton={"add"}
                        style={{textTransform: "uppercase", marginLeft: 10}}
                        size={"medium"}
                        onClick={() => {
                            onChooseACurator(userID);
                            this.setState({userID: ""})
                            this.onCloseModal();
                        }}
                    />
                </Modal.Actions>
            </>
        )
    }

    renderUserImageHover = (user) => {
        const arrUserInfo = _.filter([
            {
                label: Config.lang('Nhan_vien'),
                value: _.join(_.compact(
                    [
                        _.trim(_.get(user, "EmployeeID", "")),
                        _.trim(_.get(user, "EmployeeName", ""))
                    ]), " - ")
            },
            {
                label: Config.lang('Phong_ban'),
                value: _.trim(_.get(user, "DepartmentName", ""))
            },
            {
                label: Config.lang('Du_an'),
                value: _.trim(_.get(user, "ProjectName", ""))
            },
            {
                label: Config.lang('Chuc_vu'),
                value: _.trim(_.get(user, "DutyName", ""))
            },
            {
                label: Config.lang('Ngay_vao_lam'),
                value: _.trim(_.get(user, "dateJoined", ""))
            }
        ], item => Boolean(item.value))
        return _.map(arrUserInfo, (item, i) => <span key={i}>{item.label}: {item.value}<br/></span>)
    }

    renderOtherInfo = (isLoading) => {
        const {isInterviewListHover} = this.state;
        const {classes, isLoadingCuratorList, loadInfoCandidate, isViewOnly} = this.props;
        const listMemberFollow = _.chain(this.getDataField("ListMemberFollow"))
            .split(',')
            .map(UserID => Config.getUser({UserID}))
            .compact()
            .value();
        const recSourceName = this.getDataField("RecSourceName");
        const arrOtherInfoCandidate = _.filter([
            {
                value: _.get(this.getDataField("RecpositionName"), "[0]", "") || this.getDataField("RecpositionName"),
                iconName: "fas fa-suitcase"
            },
            {
                value: _.join(_.compact([this.formatDate(this.getDataField("ReceiveDateFrom")),
                    this.formatDate(this.getDataField("ReceiveDateTo"))
                ]), " - "),
                iconName: "far fa-clock"
            },
            {
                value: _.join(_.compact([this.formatCurrency(this.getDataField("SalaryFrom")),
                    this.formatCurrency(this.getDataField("SalaryTo"))
                ]), " - "),
                iconName: "fal fa-money-check-alt"
            },
            {
                value: this.getDataField("WorkPlace"),
                iconName: "fal fa-user-friends"
            }
        ], item => !!item.value)
        const isMoved = !!_.get(loadInfoCandidate,"[0]IsMoved", _.get(loadInfoCandidate,"IsMoved", 0));
        const isEmployee = !!_.get(loadInfoCandidate,"[0]IsEmployee", _.get(loadInfoCandidate,"IsEmployee", 0));
        const isRejected = _.upperCase(_.get(loadInfoCandidate,"[0]StageID", "")) === "REJECTED";
        const ApproveLevel = loadInfoCandidate[0]?.ApproveLevel || 0;
        const ApprovalStatus = loadInfoCandidate[0]?.ApprovalStatus || 0;
        return (
            <>
                <Col md={3} xs={12} className={classes.borderSide}>
                    <div className={classes.otherInfo}>
                        <div className={classes.flexDColumn}>
                            <Typography gutterBottom className={classes.otherInfoTitle} variant="subtitle1">
                                {Config.lang("Nguoi_phu_trach")}
                                <IconButton
                                    disabled={isMoved || isRejected || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                    style={{marginLeft: 5}}
                                    aria-label="edit"
                                    size="small"
                                    onClick={this.onOpenModal}
                                >
                                    <Icons fontSize={"small"} name={"edit"}/>
                                </IconButton>
                            </Typography>
                            <div
                                style={{display: "flex", flexWrap: "wrap"}}>
                                {!isLoading ? _.map(listMemberFollow, (item, i) => (
                                    <div key={i}
                                         onMouseLeave={() => this.setState({isInterviewListHover: false})}
                                         onMouseEnter={() => this.setState({isInterviewListHover: true})}
                                         className={classes.userImage}>
                                        <UserImage
                                            keyExpr={"UserID"}
                                            valueExpr={"UserID"}
                                            renderItemHover={(user) => this.renderUserImageHover(user)}
                                            allowHover={true}
                                            data={item}
                                            width={45}
                                            height={45}
                                        />
                                        {isInterviewListHover ? <IconButton
                                            disabled={isMoved || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                                            onClick={() => this.onDeleteInterviewer(item)}
                                            className={classes.iconRemoveInterviewer} size="small">
                                            <Icons fontSize={"small"} typeIcon={"filled"} name={"cancel"}/>
                                        </IconButton> : ""}
                                    </div>)) : this.renderSkeletonImage(45, {marginRight: 8}, listMemberFollow.length)
                                }
                                {isLoadingCuratorList ? <ReactLoading
                                    type={'spin'}
                                    height={45}
                                    width={45}
                                    className={classes.fillLoading}/> : ""}
                            </div>
                            <Typography gutterBottom className={classes.otherInfoTitle} variant="subtitle1">
                                {Config.lang("Nguon_ung_vien")}
                            </Typography>
                            <Typography variant="subtitle1">
                                {!isLoading ? recSourceName : recSourceName ? <Skeleton animation="wave"/> : ""}
                            </Typography>
                        </div>
                    </div>
                </Col>
                <Col md={4} xs={12}>
                    <div className={classes.flexDColumn}>
                        <Typography gutterBottom variant="subtitle1" className={classes.otherInfoTitle}>
                            {Config.lang("Vi_tri_cong_viec")}
                        </Typography>
                        {!isLoading ? _.map(arrOtherInfoCandidate, (item, i) => (
                            <div key={i} className={this.clsx(classes.flexDRow, classes.iconWithLabel)}>
                                <Icon
                                    fontSize="small"
                                    className={item.iconName}
                                />
                                <div style={{wordBreak: "break-all"}}>
                                    <Typography className={item.iconName === "fas fa-suitcase" ? classes.infoText : ""}
                                                variant={item.iconName === "fas fa-suitcase" ? "subtitle1" : "subtitle2"}>
                                        {item.value}
                                    </Typography>
                                </div>
                            </div>
                        )) : this.renderInfoSkeletonIconWithLabel(arrOtherInfoCandidate.length)}
                    </div>
                </Col>
            </>)
    }

    renderSkeletonImage = (size, styles = {}, n = 1) => {
        return _.map(Array(n), (_, i) => <Skeleton key={i} style={{...styles}}
                                                   animation={(i + 1) === n && n !== 1 ? "pulse" : "wave"}
                                                   variant="circle"
                                                   width={size} height={size}/>)
    }

    renderInfoSkeletonIconWithLabel = (nLine = 3) => {
        const {classes, mode} = this.props;
        const isMultiColumnInfo = mode === "overview";
        return _.map(Array(nLine), (_, i) => {
                const offsetWSkLabel = (i + 1) === nLine ? 10 : 0;
                return (
                    <div key={i} className={this.clsx(classes.flexDRow, classes.iconWithLabel)}>
                        {this.renderSkeletonImage(25, {marginRight: 5})}
                        <div className={classes.skeletonInfo}>
                            <Typography className={classes.skeletonInfoContent} component="div">
                                <Skeleton width={(isMultiColumnInfo ? 100 - offsetWSkLabel : 45 - offsetWSkLabel) + "%"}
                                          animation={(i + 1) === nLine ? "pulse" : "wave"}/>
                            </Typography>
                        </div>
                    </div>
                )
            }
        )
    }

    renderCandidateProfile = (isLoading) => {
        const {classes, mode, isW25F2023} = this.props;
        const candidatePictureURL = this.getDataField("CandidatePictureURL");
        const urlPicture =
            candidatePictureURL ? (
                    candidatePictureURL.indexOf("http") < 0
                        ? Config.getCDNPath() + candidatePictureURL
                        : candidatePictureURL)
                : "";
        const candidateName = this.getDataField("CandidateName",);
        const nSex = this.getDataField("Sex", "SexName");
        const arrInfoCandidate = _.filter([
            {
                value: _.isNumber(nSex) ? (_.toNumber(nSex) === 0 ? Config.lang("Nam1") : _.toNumber(nSex) === 1 ? Config.lang("Nu") : "") : nSex,
                iconName: "far fa-user"
            },
            {
                value: this.formatDate(this.getDataField("BirthDate")),
                iconName: "far fa-gift"
            },
            {
                value: this.getDataField("Mobile"),
                iconName: "far fa-phone"
            },
            {
                value: this.getDataField("CandidateEmail", "Email"),
                iconName: "far fa-envelope"
            },
            {
                value: this.formatDate(this.getDataField("ReceivedDate", "ApplyDate"), "DD/MM/YYYY, kk:mm:ss"),
                iconName: "far fa-file"
            },
        ], item => Boolean(item.value));
        let breakPoint = {md: 9};
        switch (mode) {
            case "overview":
                breakPoint = {md: 5}
                break;
            case "apply":
                breakPoint = {}
                break;
            default:
                break;
        }
        const isMultiColumnInfo = mode === "overview";
        let arrClassNameContainer = [classes.containerProfile, classes.containerProfileW25F2023];
        if(!isW25F2023) arrClassNameContainer.pop();
        return (
            <Col {...breakPoint} xs={12} className={classes.colProfile}>
                <div className={_.join(arrClassNameContainer, " ")}>
                    <div className={classes.candidateAvatar}>
                        {!isLoading ? <UserImage
                            className={classes.boxShadow}
                            src={urlPicture}
                            width={120}
                            height={120}
                        /> : this.renderSkeletonImage(120)}
                    </div>
                    <div style={{width: "100%"}} className={this.clsx(classes.profile, classes.subText)}>
                        <Typography className={classes.candidateName} gutterBottom
                                    variant="h5">
                            {!isLoading ? candidateName :
                                <Skeleton animation="wave" width={isMultiColumnInfo ? "80%" : "55%"}/>}
                        </Typography>
                        {!isLoading ? _.map(arrInfoCandidate, (item, i) =>
                            (<div key={i} className={this.clsx(classes.flexDRow, classes.iconWithLabel)}>
                                <Icon fontSize="small" className={item.iconName}/>
                                <div style={{wordBreak: "break-all"}}>
                                    <Typography variant="subtitle2">
                                        {item.iconName === "far fa-file" ? `Apply: ${item.value}` : item.value}
                                    </Typography>
                                </div>
                            </div>)
                        ) : this.renderInfoSkeletonIconWithLabel(arrInfoCandidate.length)}
                    </div>
                </div>
            </Col>)
    }

    render() {
        const {classes, mode, isLoading, data} = this.props;
        const {openEdit, loadingForm} = this.state;
        let cardContent;
        let typeLoading = loadingForm;
        if (!_.isEmpty(data) && !_.isUndefined(isLoading)) {
            typeLoading = isLoading
        }

        let otherInfo = "";
        let className = classes.rowCardContent;
        switch (mode) {
            case "standard":
                otherInfo = this.renderCalendarInfo(typeLoading);
                className = this.clsx(className, classes.alignCenterMd);
                break;
            case "overview":
                otherInfo = this.renderOtherInfo(typeLoading);
                break;
            default:
                break;
        }
        cardContent = <Row className={className}>
            {this.renderCandidateProfile(typeLoading)}
            {otherInfo}
        </Row>
        return (
            <>
                <Modal open={openEdit} maxWidth={"sm"} fullWidth={true}
                       onClose={this.onCloseModal}
                       title={Config.lang("Dieu_chinh_nguoi_phu_trach")}
                >
                    {this.renderModalEdit()}
                </Modal>
                <Card variant="outlined" className={classes.boxShadowCard}>
                    <CardContent>
                        {cardContent}
                    </CardContent>
                </Card>
            </>
        )
    }

    componentDidUpdate(prevProps) {
        if (_.get(prevProps, "dataParam.CandidateID", "") !== _.get(this.props, "dataParam.CandidateID", "")) {
            this.setState({loadingForm: true});
            const params = {
                CandidateID: this.props.dataParam.CandidateID,
                RecInfoID: this.props.dataParam.RecInfoID,
                InterviewFileID: this.props.dataParam.InterviewFileID
            }
            this.loadForm(params);
        }
    }

    componentDidMount = () => {
        const {dataParam} = this.props;
        if (!_.isEmpty(dataParam)) {
            this.setState({loadingForm: true});
            this.loadForm();
        }
    }
}

CandidateProFileCard.propTypes = {
    data: PropTypes.object, // { CandidateID, RecInfoID, InterviewFileID, CandidateName, CandidateEmail, ...} passed only if `dataParam` not provided
    dataParam: PropTypes.object,// { CandidateID, RecInfoID, InterviewFileID } passed only if `data` not provided
    formID: PropTypes.string, // passed only if `data` not provided
    mode: PropTypes.oneOf(["standard", "overview", "apply", ""]),
    isLoading: PropTypes.bool, // passed only if `data` provided
    isLoadingCuratorList: PropTypes.bool,
    isW25F2023: PropTypes.bool,
};

CandidateProFileCard.defaultProps = {
    dataParam: {},
    data: {},
    mode: "standard",
    isLoadingCuratorList: false,
    isViewOnly: false,
    isW25F2023: false,
};

export default compose(connect(
    (state) => ({
        getCandidateProfile: state.general.getCandidateProfile,
        loadInfoCandidate: state.W25F2012.loadInfoCandidate,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch)
    })), withStyles(styles, {withTheme: true}))(CandidateProFileCard);
