/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/19/2020
 * @Example 
 */

import React                         from "react";
import PropTypes                     from "prop-types";
import Config                        from "../../../../config";
import {Typography, withStyles}      from "@material-ui/core";
import {compose} from "redux";
import {connect}                     from "react-redux";
import Divider                       from "@material-ui/core/Divider";
import {Checkbox}                    from "../../../common/form-material";

const styles = theme => ({
    item:    {
        padding: "15px 0"
    },
    heading: {
        fontSize:      12,
        fontWeight:    'bold',
        textTransform: "uppercase",
        transform:     "translateY(1px)",
        padding: "10px 0"
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    danger:  {
        color: theme.palette.danger.main,
    },
});

const listStatus = [
    {
        ID: "PUBLIC",
        Name: "Dang_cong_khai",
        Description: "Dang_cong_khai_W25DESC",
        Color: "success",
    },
    {
        ID: "INTERNAL",
        Name: "Dang_noi_bo",
        Description: "Dang_noi_bo_W25DESC",
        Color: "warning",
    },
    {
        ID: "DRAFT",
        Name: "Nhap_",
        Description: "Nhap_W25DESC",
        Color: "",
    },
    {
        ID: "CLOSE",
        Name: "Dong1",
        Description: "Dong_W25DESC",
        Color: "danger",
    },
];
class W25F2011Status extends React.PureComponent {
    render() {
        const {classes, disabled, onChangeStatusID, isLoadingTabStatus, statusID} = this.props;
        return (
            <div style={{width: '100%'}}>
                {listStatus && listStatus.map((status, idx) => {
                    return (
                        <div key={idx} className={classes.item}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{verticalAlign: "top"}}>
                                            <Checkbox
                                                checked={statusID === status.ID}
                                                value={status.ID}
                                                size={"medium"}
                                                label={""}
                                                disabled={isLoadingTabStatus || disabled}
                                                color={"primary"}
                                                onChange={onChangeStatusID}
                                            />
                                        </td>
                                        <td>
                                            <Typography className={classes.heading + " " + (classes[status.Color] ? classes[status.Color] : "")}>{Config.lang(status.Name)}</Typography>
                                            <Typography style={{marginBottom: 10}} className={"text-muted mgb10"} variant={"body2"}>{Config.lang(status.Description)}</Typography>
                                            {idx < listStatus.length - 1 && <Divider />}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                })}
            </div>
        );
    }

}

W25F2011Status.propTypes = {
    statusID: PropTypes.number,
    onChangeStatusID: PropTypes.func,
    isLoadingTabStatus: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default compose(connect(null,
    null, null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(W25F2011Status);
