/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import Config                        from "../../../../config";
import {MForm}                       from "../../../common/form-material";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../redux/W2X/W25F2011/W25F2011_actions";
import withStyles                    from "@material-ui/core/styles/withStyles";
import {FormControl, FormHelperText} from "@material-ui/core";
import {Editor}                      from "@tinymce/tinymce-react/lib/es2015/main/ts";

const styles = theme => {

};
class W25F2011Questions extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            QuestionContent: "",
            errorQuestionContent: "",
        };
        this.oldData = null;
        this.refEditor = null;
        // this.isSetData = false;
    }

    loadForm = () => {
        const { loadForm } = this.props;
        if (loadForm) {
            loadForm(3, (error, data) => {
                this.oldData = {QuestionContent: data.QuestionContent || ""};
                this.setState({QuestionContent: data.QuestionContent || ""});
            });
        }
    };

    componentDidMount () {
        const {RecInfoID} = this.props.data;
        if (RecInfoID) {
            this.loadForm();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        // const {QuestionContent} = this.state;
        if (prevProps.data && this.props.data && prevProps.data.RecInfoID !== this.props.data.RecInfoID) {
            const {RecInfoID} = this.props.data;
            if (RecInfoID) {
                this.loadForm();
            }
        }
        // if(this.refEditor && !this.isSetData && this.refEditor.getContent() !== QuestionContent) {
        //     this.refEditor.setContent(QuestionContent || "");
        //     this.isSetData = true;
        // }
    }

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {onChange} = this.props;
        if (onChange) onChange(key, {value: e}, {QuestionContent: e}, this.oldData);
    };

    saveData = async () => {
        const {RecInfoID} = this.props.data;
        const dataEditor = this.refEditor ? this.refEditor.getContent() : "";
        const params = {
            RecInfoID: RecInfoID || "",
            QuestionContent: dataEditor
        };

        const validateForm = MForm.isRequired(dataEditor, "errorQuestionContent");
        if (validateForm && validateForm.errorQuestionContent) {
            this.setState(validateForm);
            return false;
        } else {
            this.setState({errorQuestionContent: ""});
        }

        return new Promise(resolve => {
            this.props.W25F2011Actions.saveQuestions(params, async (error, data) => {
                if (error) {
                    Config.popup.show("ERROR", error);
                    return resolve(false);
                }
                if (data && data.Status === 1) {
                    Config.popup.show("INFO", data.Message);
                    return resolve(false);
                } else {
                    Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                    return resolve(true);
                }
            });
        });

    };

    render() {
        const { classes, disabled } = this.props;
        const {errorQuestionContent, QuestionContent} = this.state;

        return (
            <React.Fragment>
                <FormControl variant={"standard"}
                             error={!!errorQuestionContent}
                             margin={"normal"}
                             className={!!errorQuestionContent ? classes.borderRed : ""}
                             fullWidth={true}>
                    <Editor
                        // value={QuestionContent}
                        initialValue={QuestionContent}
                        disabled={disabled}
                        init={{
                            height: 360,
                            plugins: "advlist lists link image",
                            toolbar:
                                "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                            menubar: false,
                        }}
                        onInit={(e, editor) => this.refEditor = editor }
                        onEditorChange={(e) => this.handleChange("QuestionContent", e)}
                    />
                    <FormHelperText>{errorQuestionContent || ""}</FormHelperText>
                </FormControl>
            </React.Fragment>
        );
    }

}

W25F2011Questions.propTypes = {
    data: PropTypes.object,
    loadForm: PropTypes.func,
    onChange: PropTypes.func
};

export default compose(connect(null,
    (dispatch) => ({
        W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles))(W25F2011Questions);