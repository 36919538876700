import React                         from "react";
import {Row, Col, FormGroup}         from 'react-bootstrap';
import Config                        from "../../../../config/index";
import PropTypes                     from "prop-types";
import {withStyles, Avatar}          from "@material-ui/core";
import Modal                         from "../../../common/modal/modal";
import Attachments                   from "../../../common/attachments/attachments";
import MForm                         from "../../../common/form-material/form";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F1000Actions          from "../../../../redux/W2X/W25F1000/W25F1000_actions";
import * as generalActions           from "../../../../redux/general/general_actions";
import _                             from "lodash";
import CDN                           from "../../../CDN";
import {
    ButtonIcon,
    Dropdown,
    TextInput,
    Button, NumberInput, Label, MoneyInput
}                           from "diginet-core-ui/components";
import DateBoxPicker        from "../../../common/form-material/date-box";
const styles = (theme)=>{
    return {
        avatarContainer: {
            flex: 1,
            display: "flex",
            justifyContent: "space-around",
            "&:hover $buttonHover": {
                opacity: 1,
                transition: "0.6s"
            },
            [theme.breakpoints.down('sm')]: {
            justifyContent:'center'
            },
        },
        avatar: {
            cursor: "pointer"
        },
        buttonHover: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 43,
            opacity: 0,
            transition: "0.6s"
        },
    };

};

class W25F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            error: {},
            uploading: false,
            loadingCboRecruitmentChanel: false,
            loadingCboTabWorkFlow: false,
            loadingPopup: false,
            dataOldAttachments: [],
            isChangePictureURL: false,
            avatarAttach: null,
            formData: {
                CandidateNameU: "",
                BirthDate: null,
                Email: "",
                Mobile: "",
                SexID: "",
                ContactAddress: "",
                IDCardNo: "",
                BirthPlaceU: "",
                Tags: "",
                PastRecruitsU: "",
                RecSourceID: "",
                YearExperience: 0,
                StageID: "",
                FacebookProfile: "",
                LinkedinProfile: "",
                HomePage: "",
                DescriptionU: "",
                CadidatePictureURL: "",
            },
            dataUpdate: {},
            isSaved: false,
        };
        this.genders = [
            {
                SexID: 0,
                SexName: Config.lang("NamU")
            },
            {
                SexID: 1,
                SexName: Config.lang("Nu")
            }
        ];
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;

    }

    setStateErrorText(value) {
        this.setState({
            error: {
                ...this.state.error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    resetForm = () => {
        this.setState({
            error: {},
            dataUpdate: {},
            dataOldAttachments: [],
            avatarAttach: null,
            formData: {
                CandidateNameU: "",
                BirthDate: null,
                Email: "",
                Mobile: "",
                SexID: "",
                ContactAddress: "",
                IDCardNo: "",
                BirthPlaceU: "",
                Tags: "",
                PastRecruitsU: "",
                RecSourceID: "",
                YearExperience: 0,
                StageID: "",
                FacebookProfile: "",
                LinkedinProfile: "",
                HomePage: "",
                DescriptionU: "",
                CadidatePictureURL: "",
            },
            isSaved: false,
        });
        this.attachments = [];
        this.deletedFile = [];
        this.upload = null;
        if (this.attRef) this.attRef.onReset();
    };

    onClose = (isSaved = false) => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose(isSaved);
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    onSaveAvatar = newAvatar => {
        if (newAvatar) {
            return new Promise((rl, rj) => {
                CDN.uploadFile(newAvatar, null, (error, res) => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return rj(error);
                    }
                    if (res && res.paths.length > 0) {
                        rl(res.paths);
                    }
                });
            });
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach(e => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    onSave = async (flag = false) => {
        const { CandidateID, RecInfoID, requiredFields } = this.props;
        const { dataUpdate } = this.state;
        const {formData, avatarAttach} = this.state;
        const { CandidateNameU, BirthDate, Email, Mobile, SexID, ContactAddress, IDCardNo, BirthPlaceU, Tags, PastRecruitsU, RecSourceID, YearExperience,
            StageID, FacebookProfile, LinkedinProfile, HomePage, DescriptionU, CadidatePictureURL} = formData;
        const apiSave = dataUpdate && dataUpdate.mode === "edit" ? "edit" : "add";
        let emailRequired = {}
        if(_.isArray(requiredFields) && requiredFields.length>0) {
            emailRequired = requiredFields.find(e => e.SqlFieldName === "Email");
        }
        const validateRules = [
            {
                name : "CandidateNameU",
                rules: "isRequired",
                value: CandidateNameU
            },
            {
                name : "SexID",
                rules: "isRequired",
                value: SexID
            },
            {
                name : "BirthDate",
                rules: "isRequired",
                value: BirthDate
            },
            {
                name : "Mobile",
                rules: "isRequired",
                value: Mobile
            },
            {
                name : "StageID",
                rules: "isRequired",
                value: StageID
            },
        ];
        if (!_.isEmpty(emailRequired) && emailRequired.ValidMode.toUpperCase() === "O") {
            validateRules.push( {
                name : "Email",
                rules: "isRequired",
                value: Email
            })
        }
        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {
            this.setStateErrorText(validateFrom);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            let newPic = !_.isEmpty(avatarAttach) && (await this.onSaveAvatar(avatarAttach));
            let params = {
                DivisionID: Config.getDivisionID(),
                CandidateNameU: CandidateNameU || "",
                CandidateID: (dataUpdate && dataUpdate.CandidateID) ? dataUpdate.CandidateID : (CandidateID || "TE169E"),
                BirthDate: BirthDate || null,
                Email: Email || "",
                Mobile: Mobile || "",
                Sex: Number(SexID) || SexID === 0 ? Number(SexID) : "",
                ContactAddress: ContactAddress || "",
                IDCardNo: IDCardNo || "",
                BirthPlaceU: BirthPlaceU || "",
                Tags: Tags || "",
                PastRecruitsU: PastRecruitsU || "",
                RecSourceID: RecSourceID || "",
                YearExperience: Number(YearExperience) || Number(YearExperience) === 0 ? String(YearExperience) : "0",
                FacebookProfile: FacebookProfile || "",
                LinkedinProfile: LinkedinProfile || "",
                HomePage: HomePage || "",
                DescriptionU: DescriptionU || "",
                CadidatePictureURL: _.get(newPic, "[0]url", CadidatePictureURL),
                dataD25T2306: JSON.stringify({
                    StageID,
                    RecInfoID:RecInfoID,
                    CandidateID: (dataUpdate && dataUpdate.CandidateID) ? dataUpdate.CandidateID : (CandidateID || "TE169E")}),
                arrAttachment: JSON.stringify(arrAttachments)

            };
            if (params.CadidatePictureURL.indexOf("/file") !== -1) {
                params.CadidatePictureURL = params.CadidatePictureURL.slice(params.CadidatePictureURL.indexOf("/file"));
            }
            if (!_.isEmpty(emailRequired) && emailRequired.ValidMode.toUpperCase() !== "C" && !Email) {
                if(emailRequired.ValidMode.toUpperCase() === "W"){
                    Config.popup.show("YES_NO", Config.lang("Ban_chua_nhap_email_ban_muon_tiep_tuc"), () => {
                        this.props.w25F1000Actions[apiSave](params,(errors,data) => {
                            if (errors) {
                                Config.popup.show('ERROR', errors);
                                return false;
                            }
                            if (data) {
                                if(data.Status === 1) {
                                    let message = data.Message || Config.lang("Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong");
                                    Config.popup.show('YES_NO', message, () => {
                                        params = {...params,code:data.code};
                                        this._onSaveData(params,flag);
                                    });
                                } else {
                                    this._removeCDN();
                                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                                    if (flag) {
                                        this.resetForm();
                                        this.setState({ isSaved: true });
                                    }
                                    else {
                                        this.onClose(true);
                                    }
                                }
                            }
                        });
                    }, () => {
                        return;
                    })
                }
            }
            else {
                this.props.w25F1000Actions[apiSave](params,(errors,data) => {
                    if (errors) {
                        Config.popup.show('ERROR', errors);
                        return false;
                    }
                    if (data) {
                        if(data.Status === 1) {
                            let message = data.Message || Config.lang("Da_ton_tai_ung_vien_ban_co_tiep_tuc_luu_khong");
                            Config.popup.show('YES_NO', message, () => {
                                params = {...params,code:data.code};
                                this._onSaveData(params,flag);
                            });
                        } else {
                            this._removeCDN();
                            Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                            if (flag) {
                                this.resetForm();
                                this.setState({ isSaved: true });
                            }
                            else {
                                this.onClose(true);
                            }
                        }
                    }
                })
            }
        }

    };

    _onSaveData = (params, flag = false) => {
        this.props.w25F1000Actions.confirm(params,(errors,data) => {
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                this._removeCDN();
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                if (flag) {
                    this.resetForm();
                    this.setState({ isSaved: true });
                }
                else {
                    this.onClose(true);
                }
            }
        })
    };

    loadCboRecruitmentChanel = () => {
        this.setLoading('CboRecruitmentChanel', true);
        this.props.w25F1000Actions.getCboRecruitmentChanel((errors) => {
            this.setLoading('CboRecruitmentChanel', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboTabWorkFlow = () => {
        const {dataUpdate} = this.state;
        const {RecInfoID} = this.props;
        const params = {
            RecInfoID: (dataUpdate && dataUpdate.RecInfoID) ?  dataUpdate.RecInfoID : RecInfoID ? RecInfoID : "",
            // RecInfoID:"804041A9-A2B1-410A-842A-F52159249B06",
        };
        this.setLoading('CboTabWorkFlow', true);
        this.props.w25F1000Actions.getCboTabWorkFlow(params,(errors) => {
            this.setLoading('CboTabWorkFlow', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadPopUp = () => {
        const { dataUpdate } = this.state;
        const {CandidateID,RecInfoID,mode} = dataUpdate;
        const params = {
            FormID: "W25F1000",
            CandidateID: (mode && mode === "edit") ? CandidateID : "",
            RecInfoID: (RecInfoID && RecInfoID) || "",
            // RecInfoID:"804041A9-A2B1-410A-842A-F52159249B06",
        };

        this.setLoading('Popup', true);
        this.props.w25F1000Actions.getDataPopup(params,(errors,data) => {
            this.setLoading('Popup', false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        CandidateNameU: data.CandidateName ? data.CandidateName  : "",
                        BirthDate: data.BirthDate ? data.BirthDate  : null,
                        Email: data.Email ? data.Email  : "",
                        Mobile: data.Mobile ? data.Mobile  : "",
                        SexID: (data && data.Sex) || data.Sex === 0 ? data.Sex  : "",
                        ContactAddress: data.ContactAddress ? data.ContactAddress  : "",
                        IDCardNo: data.IDCardNo ? data.IDCardNo  : "",
                        BirthPlaceU: data.BirthPlaceU ? data.BirthPlaceU  : "",
                        Tags: data.Tags ? data.Tags  : "",
                        PastRecruitsU: data.PastRecruitsU ? data.PastRecruitsU  : "",
                        RecSourceID: data.RecSourceID ? data.RecSourceID  : "",
                        YearExperience: (data && data.YearExperience) || data.YearExperience === 0 ? data.YearExperience  : "0",
                        StageID: data.StageID ? data.StageID  : "",
                        FacebookProfile: data.FacebookProfile ? data.FacebookProfile  : "",
                        LinkedinProfile: data.LinkedinProfile ? data.LinkedinProfile  : "",
                        HomePage: data.HomePage ? data.HomePage  : "",
                        DescriptionU: data.DescriptionU ? data.DescriptionU  : "",
                        CadidatePictureURL: data.CadidatePictureURL ? Config.getCDNPath() + data.CadidatePictureURL  : "",
                    }
                });
            }
        });
    };

    loadAttachments = () => {
        const {dataUpdate} = this.state;
        const param = {
            KeyID: dataUpdate && dataUpdate.CandidateID ?  dataUpdate.CandidateID : "",
            TableName: "D25T1041"
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    componentDidMount() {
        this.loadCboTabWorkFlow();
        this.loadCboRecruitmentChanel();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.mode === "add") {
            this.resetForm();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { CandidateID, FormID, RecInfoID, mode} = this.props;
        if (prevProps.CandidateID !== CandidateID && CandidateID !== "") {
            this.setState({dataUpdate: {CandidateID, FormID, RecInfoID, mode}}, () => {
                if(mode === "edit") {
                    this.loadAttachments();
                    this.loadPopUp();
                }
            });
        }
    }

    handleChange = (nameField,e) => {
        if(!nameField) return false;
        let value = _.get(e, "target.value", _.get(e, "value", ""));

        if (nameField === "Mobile" && Number(value) < 0) {
            value = 0;
        }

        if (nameField === "YearExperience" && Number(value) < 0) {
            value = 0;
        }

        if (nameField === "IDCardNo" && Number(value) < 0) {
            value = 0;
        }

        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    [nameField]: value,
                },
            }, () => {
                if(!!this.state.error[nameField]) {
                    this.setState({
                        error: {
                            ...this.state.error,
                            [nameField]: (!_.isEmpty(value) || _.isNumber(value)) ? "" : Config.lang("Truong_nay_bat_buoc_nhap")
                        }
                    });
                }
            })
    };

    onRemoveFile = () => {
        const { formData } = this.state;
        const CadidatePictureURL = _.get(formData, "CadidatePictureURL", "");
        this.deletedAvatar(CadidatePictureURL);
        this.setState({
            avatarAttach: null,
            formData: {
                ...this.state.formData,
                CadidatePictureURL: ""
            }
        });
    };

    checkFileType = filename => {
        const extension = filename.substr(filename.lastIndexOf(".")).toLowerCase();
        const allowedExtensions = [".jpg", ".png"];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };


    deletedAvatar = URL => {
        if (!_.isEmpty(URL) && this.deletedFile.filter(e => e.URL === URL).length === 0)
            this.deletedFile = [...this.deletedFile, { URL }];
    };

    onUpoadAvatar = () => {
        const { formData } = this.state;
        const CadidatePictureURL = _.get(formData, "CadidatePictureURL", "");
        this.deletedAvatar(CadidatePictureURL);
        const arrFile = this.upload.files;
        const sizeLimit = 5000000;
        this.setState({ isChangePictureURL: true }, () => {
            for (let i = 0; i < arrFile.length; i++) {
                if (arrFile[i].size / 1024 > Number(sizeLimit)) {
                    Config.popup.show("INFO", "Image is too large ." + sizeLimit + " KB");
                } else if (this.checkFileType(arrFile[i].name) === false) {
                    Config.popup.show("INFO", "Wrong format.");
                } else {
                    let file = arrFile[i];
                    this.setState({
                        avatarAttach: [file],
                        formData: {
                            ...this.state.formData,
                            CadidatePictureURL: URL.createObjectURL(arrFile[i])
                        }
                    });
                }
            }
        });
    };

    onBlur = (key,e) => {
        const {formData} = this.state;
        const {SexID, BirthDate, StageID} = formData;
        const value = _.get(e, 'target.value',_.get(e, 'value', e));
        const validateRules = [];
        switch (key) {
            case "CandidateNameU":
                if(value === "") {
                    validateRules.push({name : "CandidateNameU",rules: "isRequired",value: value})
                }
                break;
            case "SexID":
                if(SexID === "") {
                    validateRules.push({name : "SexID",rules: "isRequired",value: ""})
                }
                break;
            case "BirthDate":
                if(BirthDate === null) {
                    validateRules.push({name : "BirthDate",rules: "isRequired",value: ""})
                }
                break;
            case "Mobile":
                if(value === "") {
                    validateRules.push({name : "Mobile",rules: "isRequired",value: value})
                }
                break;
            case "StageID":
                if(StageID === "") {
                    validateRules.push({name : "StageID",rules: "isRequired",value: ""})
                }
                break;
            default:
                break;
        }
        const validateFrom = MForm.formValidation(validateRules);
        if (Object.keys(validateFrom).length > 0) {
            this.setStateErrorText(validateFrom);
            return false;
        }
    };

    render() {
        const {classes, open, getCboRecruitmentChanels, getCboTabWorkFlow, isPermission, RecInfoID, requiredFields} = this.props;
        const { dataOldAttachments, loadingCboRecruitmentChanel, error, formData, loadingPopup, loadingCboTabWorkFlow, uploading, isSaved} = this.state;
        const emailRequired = _.isArray(requiredFields) && requiredFields.length > 0 && requiredFields.find(e => e.SqlFieldName === "Email");
        return(

            <React.Fragment>
                <Modal open={open}  maxWidth={"md"} onClose={() => this.onClose(isSaved)} fullWidth={true}  title={Config.lang("Them_ung_vien")}>
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div>
                                    <FormGroup>
                                        <Row className={"display_row align-center flex-wrap"}>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Row className={"display_row flex-wrap"}>
                                                    <Col xs={12} sm={12} md={3} lg={3}>
                                                        <div className={""}><div className={classes.avatarContainer}>
                                                            <Avatar
                                                                // disabled={loading}
                                                                onClick={() => this.upload.click()}
                                                                className={classes.avatar}
                                                                style={{
                                                                    width: "125px",
                                                                    height: "125px",
                                                                }}
                                                                src={
                                                                    !_.isEmpty(formData.CadidatePictureURL)
                                                                        ? formData.CadidatePictureURL
                                                                        : require("../../../../assets/images/general/user_default.svg")
                                                                }
                                                            />
                                                            <input type="file" id="file" onChange={this.onUpoadAvatar} ref={ref => (this.upload = ref)} style={{ display: "none" }} />
                                                            <div className={classes.buttonHover}>

                                                                <ButtonIcon
                                                                    disabled={loadingPopup}
                                                                    viewType={"text"}
                                                                    color={"primary"}
                                                                    name={"edit"}
                                                                    size={"medium"}
                                                                    onClick={() => this.upload.click()}
                                                                />
                                                                <ButtonIcon
                                                                    disabled={loadingPopup}
                                                                    viewType={"text"}
                                                                    color={"warning"}
                                                                    name={"delete"}
                                                                    size={"medium"}
                                                                    onClick={this.onRemoveFile}
                                                                />
                                                            </div>
                                                        </div></div>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={9} lg={9}>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                                            <TextInput
                                                                                error={error && error["CandidateNameU"]}
                                                                                label={Config.lang("Ho_ten_ung_cu_vien")}
                                                                                disabled={loadingPopup}
                                                                                required={true}
                                                                                onBlur={e => this.onBlur('CandidateNameU',e)}
                                                                                value={formData.CandidateNameU || ""}
                                                                                onChange={e => this.handleChange("CandidateNameU", e)}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                                            <Dropdown
                                                                                error={error && error["SexID"]}
                                                                                label={Config.lang('Gioi_tinh')}
                                                                                clearAble
                                                                                dataSource={this.genders}
                                                                                displayExpr={'SexName'}
                                                                                valueExpr={'SexID'}
                                                                                required={true}
                                                                                disabled={loadingPopup}
                                                                                value={formData.SexID || formData.SexID === 0 ? formData.SexID   : ""}
                                                                                onChange={(e) => this.handleChange("SexID", e)}
                                                                                inputProps={{
                                                                                    onBlur: () => {
                                                                                        this.onBlur('SexID')
                                                                                    }
                                                                                }}
                                                                                viewType={'underlined'}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                                            <Label required={true}>{Config.lang('Ngay_sinh')}</Label>
                                                                            <DateBoxPicker
                                                                                error={error && error["BirthDate"]}
                                                                                stylingMode={"underlined"}
                                                                                required={true}
                                                                                margin={"none"}
                                                                                value={formData.BirthDate || ""}
                                                                                placeholder={"dd/mm/yyyy"}
                                                                                useMaskBehavior={true}
                                                                                showClearButton={true}
                                                                                width={"100%"}
                                                                                disabled={loadingPopup}
                                                                                dateBoxProps={{
                                                                                    onFocusOut: () => {
                                                                                        this.onBlur('BirthDate');
                                                                                    }
                                                                                }}
                                                                                onValueChanged={e => this.handleChange("BirthDate", e)}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    error={error && error["Email"]}
                                                    label={Config.lang("Email")}
                                                    disabled={loadingPopup}
                                                    inputProps={{
                                                        type: "email"
                                                    }}
                                                    required={!_.isEmpty(emailRequired) && emailRequired.ValidMode.toUpperCase() === "O"}
                                                    value={formData.Email || ""}
                                                    onChange={e => this.handleChange("Email", e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Cong_viec_truoc_day")}
                                                    disabled={loadingPopup}
                                                    value={formData.PastRecruitsU || ""}
                                                    onChange={(e) => this.handleChange('PastRecruitsU', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    error={error && error["Mobile"]}
                                                    label={Config.lang("So_dien_thoai")}
                                                    value={formData.Mobile}
                                                    required={true}
                                                    allowZero={true}
                                                    decimalDigit={0}
                                                    disabled={loadingPopup}
                                                    onBlur={e => this.onBlur('Mobile',e)}
                                                    onChange={(e) => this.handleChange('Mobile', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <Dropdown
                                                    label={Config.lang('Nguon_ung_tuyen')}
                                                    clearAble
                                                    dataSource={getCboRecruitmentChanels}
                                                    displayExpr={'RecSourceName'}
                                                    valueExpr={'RecSourceID'}
                                                    disabled={loadingPopup}
                                                    loading={loadingCboRecruitmentChanel}
                                                    value={formData.RecSourceID || ""}
                                                    onChange={(e) => this.handleChange("RecSourceID", e)}
                                                    viewType={'underlined'}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Dia_chi")}
                                                    disabled={loadingPopup}
                                                    value={formData.ContactAddress || ""}
                                                    onChange={(e) => this.handleChange('ContactAddress', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <NumberInput
                                                    label={Config.lang("So_nam_kinh_nghiem")}
                                                    value={formData.YearExperience}
                                                    min={0}
                                                    decimalSymbol={"."}
                                                    disabled={loadingPopup}
                                                    onChange={(e) => this.handleChange('YearExperience', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    label={Config.lang("Chung_minh_thu_ho_chieu")}
                                                    allowZero={true}
                                                    decimalDigit={0}
                                                    value={formData.IDCardNo}
                                                    disabled={loadingPopup}
                                                    onChange={(e) => this.handleChange('IDCardNo', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Facebook_profile")}
                                                    disabled={loadingPopup}
                                                    value={formData.FacebookProfile || ""}
                                                    onChange={(e) => this.handleChange('FacebookProfile', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Nguyen_quan")}
                                                    disabled={loadingPopup}
                                                    value={formData.BirthPlaceU || ""}
                                                    onChange={(e) => this.handleChange('BirthPlaceU', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Linkedin_profile")}
                                                    disabled={loadingPopup}
                                                    value={formData.LinkedinProfile || ""}
                                                    onChange={(e) => this.handleChange('LinkedinProfile', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Tags")}
                                                    disabled={loadingPopup}
                                                    value={formData.Tags || ""}
                                                    onChange={(e) => this.handleChange('Tags', e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <TextInput
                                                    label={Config.lang("Home_page")}
                                                    disabled={loadingPopup}
                                                    value={formData.HomePage || ""}
                                                    onChange={(e) => this.handleChange('HomePage', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    {
                                        RecInfoID  && (
                                            <FormGroup>
                                                <Row>
                                                    <Col xs={12} sm={12} md={6} lg={6}>
                                                        <Dropdown
                                                            error={error && error["StageID"]}
                                                            label={Config.lang('Giai_doanF')}
                                                            clearAble
                                                            dataSource={getCboTabWorkFlow}
                                                            displayExpr={'StageName'}
                                                            valueExpr={'StageID'}
                                                            required={true}
                                                            loading={loadingCboTabWorkFlow}
                                                            value={formData.StageID || ""}
                                                            disabled={loadingPopup}
                                                            onChange={(e) => this.handleChange("StageID", e)}
                                                            inputProps={{
                                                                onBlur: () => {
                                                                    this.onBlur('StageID')
                                                                }
                                                            }}
                                                            viewType={'underlined'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        )
                                    }

                                    <FormGroup>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={12} sm={12} md={12} lg={12}>
                                                            <TextInput
                                                                label={Config.lang("Tom_tat_ung_vien")}
                                                                disabled={loadingPopup}
                                                                value={formData.DescriptionU || ""}
                                                                onChange={(e) => this.handleChange('DescriptionU', e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{ marginBottom: 34 }}>
                                        <Attachments
                                            ref={ref => this.attRef = ref}
                                            showButton={true}
                                            files={dataOldAttachments}
                                            maxLength={5}
                                            disabled={loadingPopup}
                                            uploading={uploading}
                                            onUploading={this.onUploading}
                                            onChanged={this.onChangeAttachments}
                                        />
                                    </FormGroup>
                                    
                                </div>
                            </Col>
                        </Row>
                    </Modal.Content>
                    <Modal.Actions>
                        <FormGroup style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
                            <Button
                                color={"info"}
                                viewType={"filled"}
                                startIcon={"Save"}
                                disabled={loadingPopup || !isPermission > 2}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                text={Config.lang('Luu')}
                                onClick={() => this.onSave()}
                            />
                            <Button
                                color={"info"}
                                viewType={"filled"}
                                startIcon={"Save"}
                                disabled={loadingPopup || !isPermission > 2}
                                style={{ textTransform: "uppercase" }}
                                text={Config.lang('Luu_va_nhap_tiep')}
                                size={"large"}
                                onClick={() => this.onSave(true)}
                            />
                        </FormGroup>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        )
    }
}

W25F1000.propTypes = {
    FormID: PropTypes.string,
    isPermission: PropTypes.number,
    CandidateID: PropTypes.string,
    RecInfoID: PropTypes.string,
    mode: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChosen: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboRecruitmentChanels: state.W25F1000.getCboRecruitmentChanels,
            getCboTabWorkFlow: state.W25F1000.getCboTabWorkFlow,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w25F1000Actions: bindActionCreators(W25F1000Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W25F1000);
