import React, {Component} from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import IconsCommon from "../../../common/icons";
import Config from "../../../../config";
import FileViewer from "../../../common/fileviewer/fileviewer";
import {
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CachedIcon from "@material-ui/icons/Cached";
import ReactLoading from "react-loading";
import _ from "lodash";

const styles = (theme) => {
    return {
        expansionSummaryRoot: {
            minHeight: "45px !important",
            "& .MuiTypography-h6": {
                color: theme.palette.text.primary,
                fontSize: "1.2rem !important",
                fontWeight: "600 !important",
            },
        },
        expansionSummaryContent: {
            margin: "0 !important",
            "&.withSubTitle": {
                flexDirection: "column",
                padding: "10px 0",
            },
        },
        boxShadowCard: {
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        },
        expansionDetailsRoot: {
            height: "100%",
            display: "block !important",
        },
        iconLabel: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        primaryText: {
            color: theme.palette.primary.main,
            fontWeight: 600,
        },
        subText: {
            color: theme.palette.text.subtext,
            fontWeight: 600,
        },
        titleExpandDetails: {
            color: "#555768",
            fontWeight: 600,
            lineHeight: 2,
            textTransform: "uppercase",
        },
        btnGroup: {
            minWidth: 96,
        },
        colorBtnSuccess: {
            "&:hover": {
                color: theme.palette.success.main,
            },
        },
        colorBtnWarning: {
            "&:hover": {
                color: theme.palette.warning.main,
            },
        },
        colorBtnDanger: {
            "&:hover": {
                color: theme.palette.danger.main,
            },
        },
        groupTitle: {display: "flex", padding: "10px 0", flexDirection: "column"},
        groupIcon: {
            display: "flex",
            alignItems: "center",
        },
        fillLoading: {
            fill: theme.palette.primary.main + "!important",
        },
    };
};

class CandidateCVCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iAttachmentCV: 0,
            dataAttachment: [],
            loadingAttachment: false,
            expanded: true,
        };
    }

    clsx = (...spread) => _.join(spread, " ");

    loadAttachments = () => {
        const {keyID} = this.props;
        const param = {
            KeyID: keyID,
            TableName: "D25T1041"
        };
        this.props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            this.setState({loadingAttachment: false});
            if (err) {
                if (err) Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                this.setState({dataAttachment: data});
            }
        });
    };

    onNextAttachmentCV = () => {
        this.setState({iAttachmentCV: this.state.iAttachmentCV + 1});
    };

    onPrevAttachmentCV = () => {
        this.setState({iAttachmentCV: this.state.iAttachmentCV - 1});
    };

    onResetAttachmentCV = () => {
        this.loadAttachments();
    };

    render() {
        const {classes} = this.props;
        const {iAttachmentCV, dataAttachment, expanded, loadingAttachment} = this.state;
        const attachmentItem = dataAttachment[iAttachmentCV];
        return (
            <Accordion expanded={expanded} className={this.clsx(classes.expansionCard, classes.shadowCard)}>
                <AccordionSummary
                    classes={{
                        root: classes.expansionSummaryRoot,
                        content: classes.expansionSummaryContent,
                    }}
                    IconButtonProps={{size: "small"}}
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Typography
                        component="div"
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography component="div" className={classes.groupTitle}>
                            <Typography variant="h6">{Config.lang("DHR_CV_ung_vien")}</Typography>
                            <Typography className={classes.subText} variant="subtitle2">
                                {Config.lang("DHR_Chi_tiet_thong_tin_ung_tuyen_cua_ung_vien")}
                            </Typography>
                        </Typography>
                        {dataAttachment.length > 0 ? <Typography component="div" className={classes.groupIcon}>
                            <IconButton disabled={!expanded} href={_.get(attachmentItem, "URL", "")} size="small">
                                <IconsCommon name={"download"}/>
                            </IconButton>
                            <IconButton disabled={iAttachmentCV === 0 || !expanded} onClick={this.onPrevAttachmentCV}
                                        size="small">
                                <KeyboardArrowLeft/>
                            </IconButton>
                            <Typography className={classes.primaryText} variant="subtitle1">
                                {iAttachmentCV + 1}
                            </Typography>
                            /
                            <Typography className={classes.subText} variant="subtitle1">
                                {dataAttachment.length}
                            </Typography>
                            <IconButton
                                disabled={(dataAttachment.length - 1 === iAttachmentCV) || !expanded}
                                onClick={this.onNextAttachmentCV}
                                size="small"
                            >
                                <KeyboardArrowRight/>
                            </IconButton>
                            <IconButton disabled={!expanded} onClick={this.onResetAttachmentCV} size="small">
                                <CachedIcon/>
                            </IconButton>
                        </Typography> : ""}
                    </Typography>
                </AccordionSummary>
                <Divider variant="middle"/>
                <AccordionDetails
                    classes={{
                        root: classes.expansionDetailsRoot,
                    }}
                >
                    {loadingAttachment ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <ReactLoading width={60} height={60} className={classes.fillLoading} type={"spin"}/>
                        </div>
                    ) : (
                        <Typography style={{height: "100vh"}}>
                            <FileViewer
                                fileName={_.get(attachmentItem, "FileName", "")}
                                filePath={_.get(attachmentItem, "URL", "")}
                                fileType={_.get(attachmentItem, "FileName", "").split(".").pop()}
                                height={"100%"}
                            />
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.modeView === "multi" && (prevProps.keyID !== this.props.keyID)) {
            this.setState({loadingAttachment: true});
            this.loadAttachments();
        }
    }

    componentDidMount() {
        this.setState({loadingAttachment: true});
        this.loadAttachments();
    }
}

export default compose(
    connect(
        (state) => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles, {withTheme: true}),
)(CandidateCVCard);

CandidateCVCard.defaultProps = {
    modeView: "default",
};