import React, {useEffect, useState, useRef} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Config from "../../../../config";
import Modal from "../../../common/modal/modal";
import {Col, Row} from "react-bootstrap";
import {Combo} from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import Api from "../../../../services/api";
import _ from "lodash";

const styles = theme => ({});

const TransRecPopup = ({
                           isOpen = false,
                           paramsProps: {
                               RecInfoID = null,
                               FormID = null,
                               CandidateID = null
                           },
                           onUpdateTransRec,
                           onClose
                       }) => {
    const [recInfoID, setRecInfoID] = useState("");
    const [dataCboRecInfo, setDataCboRecInfo] = useState(null);
    const [isLoadingCboRecInfo, setLoadingCboRecInfo] = useState(false);
    const [isUpdating, setUpdating] = useState(false);

    const timerLoadingCboRecInfo = useRef(null);

    const handleResponse = (res) => {
        const resData = _.get(res, "data", {});
        const resDataStatus = _.get(resData, "Status", 0);
        const resMessageError = _.get(res, "message", "") || _.get(resData, "Message", "");
        if (resDataStatus === 1 || resMessageError) {
            Config.popup.show("ERROR", resMessageError || Config.lang("DHR_Loi_chua_xac_dinh"));
            return false;
        }
        return resData;
    }

    useEffect(() => {
        (async () => {
            setLoadingCboRecInfo(true);
            const res = await Api.put('/w25f2022/load-cbo-recinfo', {RecInfoID});
            timerLoadingCboRecInfo.current = setTimeout(() => {
                setLoadingCboRecInfo(false)
            }, 300);
            const resData = handleResponse(res);
            if (resData) setDataCboRecInfo(resData);
        })();
        return () => {
            if (timerLoadingCboRecInfo.current) clearTimeout(timerLoadingCboRecInfo.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = async () => {
        const params = {
            FormID,
            RecInfoID: recInfoID,
            CandidateID,
            Values: '',
            Mode: 0,
            Language: Config.language || "84"
        };
        try {
            setUpdating(true);
            const resCheck = await Api.put("/w25f2022/check-update", params);
            const resCheckData = handleResponse(resCheck, true);
            if (!!!resCheckData) {
                setUpdating(false);
                return false;
            }
            const resUpdate = await Api.put("/w25f2022/update", {...params, RecInfoID});
            const resUpdateData = handleResponse(resUpdate, true);
            if (!!!resUpdateData) {
                setUpdating(false);
                return false;
            }
            const resInsert = await Api.put("/w25f2022/insert", _.pick(params, ["Language", "RecInfoID", "CandidateID"]));
            const resInsertData = handleResponse(resInsert, true);
            if (!!!resInsertData) {
                setUpdating(false);
                return false;
            }
            onUpdateTransRec(recInfoID);
            Config.notify.show('success', Config.lang("DHR_Chuyen_tin_tuyen_dung_thanh_cong"), 2000);
            onClose(true);
            setUpdating(false);
        } catch (e) {
        }
    };

    return <Modal
        open={isOpen}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => onClose(false)}
        title={Config.lang("DHR_Chuyen_tin_tuyen_dung")}
    >
        <>
            <Modal.Content>
                <Row>
                    <Col xs={12}>
                        <Combo
                            loading={isLoadingCboRecInfo}
                            dataSource={dataCboRecInfo}
                            valueExpr={"RecInfoID"}
                            displayExpr={data => _.join(_.compact([
                                _.get(data, "RecInfoCode", ""),
                                _.get(data, "RecInfoTitle", "")
                            ]), " - ")}
                            itemRender={data => _.join(_.compact([
                                _.get(data, "RecInfoCode", ""),
                                _.get(data, "RecInfoTitle", "")
                            ]), " - ")}
                            stylingMode={"underlined"}
                            disabled={isUpdating}
                            searchEnabled={true}
                            shrink={true}
                            value={recInfoID}
                            label={Config.lang("DHR_Tin_tuyen_dung")}
                            onValueChanged={({value}) => {
                                setRecInfoID(value)
                            }}
                            margin={"normal"}
                        />
                    </Col>
                </Row>
            </Modal.Content>
            <Modal.Actions style={{justifyContent: "flex-start"}}>
                <ButtonGeneral
                    name={Config.lang('DHR_Cap_nhat')}
                    disabled={_.isEmpty(recInfoID) || isUpdating}
                    typeButton={'save'}
                    size={'medium'}
                    className={'pull-right mgr5'}
                    onClick={onUpdate}/>
                <ButtonGeneral
                    name={Config.lang('DHR_Huy_bo')}
                    typeButton={'cancel'}
                    size={'medium'}
                    disabled={isLoadingCboRecInfo || isUpdating}
                    className={'pull-right mgr5'}
                    onClick={() => onClose()}/>
            </Modal.Actions>
        </>
    </Modal>
}

export default compose(
    connect(
        null,
        null
    ),
    withStyles(styles, {withTheme: true})
)(TransRecPopup);