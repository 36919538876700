
/**
 * @copyright 2020 @ DigiNet
 * @author HOANGNAM
 * @create 06/22/2020
 * @Example
 */

import { withStyles } from "@material-ui/core";
import { Column } from "devextreme-react/data-grid";
import {Button, ButtonIcon, DatePicker, Dropdown, Tooltip } from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W25F2000Actions from "../../../../redux/W2X/W25F2000/W25F2000_actions";
import Status from "../../../common/status/status";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import W84F3005      from "../../../W8X/W84/W84F3005/W84F3005";

const styles = {

    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    }
};
class W25F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            gridLoading: false,
            voucherDateFrom: null,
            voucherDateTo: null,
            loadingCboEmployees: false,
            loadingCboProjects: false,
            cboAppStatusLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            filter: {
                Employee: '',
                AppStatus: '',
                Project: '',
            },
            w84F3005PopupData: {
                TransID: null
            },
            showW84F3005Popup: false,
        };
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.filterCboProjects = {
            strSearch: '',
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            strSearch: '',
            skip: 0,
            limit: 50
        }

    }

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });

    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2000", isPer => {
            this.setState({ iPermission: isPer });
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    filterChange = (key, data) => {
        if (!key) return false;
        const { value } = data;
        const { filter } = this.state;
        switch (key) {
            case "VoucherDateFrom":
                this.setState({ voucherDateFrom: value });
                break;
            case "VoucherDateTo":
                this.setState({ voucherDateTo: value });
                break;
            case "EmployeeID":
                this.setState({ filter: { ...filter, Employee: value } });
                break;
            case "AppStatusID":
                this.setState({ filter: { ...filter, AppStatus: value } });
                break;
            case "ProjectID":
                this.setState({ filter: { ...filter, Project: value } });
                break;
            default:
                break;
        }
    };

    loadCboAppStatus = () => {
        const params = {
            Language: Config.language || '84',
            FormID: "STANDARD"
        };
        this.setState({ cboAppStatusLoading: true });
        this.props.w25f2000Actions.getCboAppStatus(params, (error) => {
            this.setState({ cboAppStatusLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });


    };

    loadCboEmployees = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboEmployees;
        const params = {
            HostID: "",
            FormID: "W25F2000",
            Language: Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch ? strSearch : ''

        };
        this.setLoading('CboEmployees', true);
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setLoading('CboEmployees', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };




    loadCboProjects = (isReset) => {
        const { skip, limit, strSearch } = this.filterCboProjects;
        const params = {
            HostID: "",
            FormID: "W25F2000",
            Language: Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch ? strSearch : ''
        };
        this.setLoading('CboProjects', true);
        this.props.generalActions.getCboProjects(params, (error, data) => {
            this.setLoading('CboProjects', false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadDataGrid = (VoucherID) => {
        const { voucher_id } = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { skip, limit } = this.filter;
        const { voucherDateFrom, voucherDateTo, filter: { Employee, Project, AppStatus } } = this.state;
        const params = {
            TransID: VoucherID || "",
            DivisionID: Config.getDivisionID(),
            VoucherDateFrom: voucherDateFrom ? voucherDateFrom : null,
            VoucherDateTo: voucherDateTo ? voucherDateTo : null,
            AppStatusID: AppStatus || AppStatus === 0 ? String(AppStatus) : "",
            EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : '%',
            Language: Config.language || '84',
            ProjectID: Project ? Project.ProjectID : '%',
            skip: skip,
            limit: limit
        };
        this.setState({ gridLoading: true });
        this.props.w25f2000Actions.loadDataGrid(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });

    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadDataGrid();

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getDataGrid } = this.props;
        Config.callChildForm({
            FormID: "W25F2000",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.redirectScreen("edit", { data: data })
        }, this.props);
    }

    onHistoryApproval = (e, data) => {
        this.setState({w84F3005PopupData: {TransID: data?.TransID || null}, showW84F3005Popup: true})
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onLoadDataFilter = () => {
        this.loadCboAppStatus();
        this.loadCboEmployees();
        this.loadCboProjects();
    }

    renderFilter = () => {
        const { voucherDateFrom, voucherDateTo, loadingCboEmployees, loadingCboProjects, dataCboEmployees, dataCboProjects,
            cboAppStatusLoading, filter: { Employee, Project, AppStatus } } = this.state;
        const { getCboAppStatus } = this.props;
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <DatePicker
                                        clearAble={true}
                                        width={"100%"}
                                        style={{ margin: 5 }}
                                        viewType={"outlined"}
                                        max={voucherDateTo}
                                        value={voucherDateFrom}
                                        displayFormat={"DD/MM/YYYY"}
                                        placeholder={"DD/MM/YYYY"}
                                        label={Config.lang("Tu")}
                                        onChange={e => this.filterChange("VoucherDateFrom", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <DatePicker
                                        clearAble={true}
                                        width={"100%"}
                                        style={{ margin: 5 }}
                                        viewType={"outlined"}
                                        min={voucherDateFrom}
                                        value={voucherDateTo}
                                        displayFormat={"DD/MM/YYYY"}
                                        placeholder={"DD/MM/YYYY"}
                                        label={Config.lang("Den")}
                                        onChange={e => this.filterChange("VoucherDateTo", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        style={{ margin: 5 }}
                                        viewType={"outlined"}
                                        valueExpr={"AppStatusID"}
                                        displayExpr={"AppStatusName"}
                                        placeholder={Config.lang("Chon")}
                                        label={Config.lang("Trang_thai")}
                                        onChange={e => this.filterChange("AppStatusID", e)}
                                        dataSource={getCboAppStatus}
                                        loading={cboAppStatusLoading}
                                        value={AppStatus}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        style={{ margin: 5 }}
                                        searchDelayTime={1000}
                                        viewType={"outlined"}
                                        valueExpr={"EmployeeID"}
                                        renderSelectedItem={"EmployeeName"}
                                        label={Config.lang("Nhan_vien")}
                                        placeholder={Config.lang("Chon")}
                                        displayExpr={"{EmployeeID} - {EmployeeName}"}
                                        onChange={(e) => this.filterChange("EmployeeID", e)}
                                        dataSource={_.get(dataCboEmployees, "rows", [])}
                                        total={_.get(dataCboEmployees, "total", 0)}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        value={Employee === "%" ? null : Employee}
                                        loading={loadingCboEmployees}
                                        onInput={(e) => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        clearAble
                                        style={{ margin: 5 }}
                                        searchDelayTime={1000}
                                        viewType={"outlined"}
                                        valueExpr={"ProjectID"}
                                        displayExpr={"ProjectName"}
                                        label={Config.lang("Du_an")}
                                        placeholder={Config.lang("Chon")}
                                        total={_.get(dataCboProjects, "total", 0)}
                                        skip={this.filterCboProjects.skip}
                                        limit={this.filterCboProjects.limit}
                                        value={Project === "%" ? null : Project}
                                        dataSource={_.get(dataCboProjects, "rows", [])}
                                        onChange={e => this.filterChange("ProjectID", e)}
                                        loading={loadingCboProjects}
                                        onInput={(e) => {
                                            this.filterCboProjects.strSearch = e.target.value;
                                            this.filterCboProjects.skip = 0;
                                            this.loadCboProjects(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboProjects.skip = e.skip;
                                            this.filterCboProjects.limit = e.limit;
                                            this.loadCboProjects();
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: 15,
                                    paddingBottom: 5
                                }}
                            >
                                <Button
                                    size={"medium"}
                                    startIcon={"Search"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID }
        });
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;
        const date = data.CreateDate ? moment.utc(data.CreateDate).format("DD/MM/YYYY kk:mm:ss") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)} />
                <div className={"w75f2000_info"}>
                    <div style={{ fontSize: '1.12rem' }}><UserName data={data} /></div>
                    {/*<div className={classes.divText}>{data.DepartmentName || ""}</div>*/}
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {data.CreateDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { classes } = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>{data.ProNotice || ""}</div>
                <div>
                    {Config.lang("Phong_ban")}:{data.DepartmentName || ""}
                </div>
                {data.ProjectName && (
                    <div>
                        {Config.lang("Du_an")}: {data.ProjectName || ""}
                    </div>
                )}
                {data.ProNumber && (
                    <div>
                        {Config.lang("So_luong_tuyen")}: {data.ProNumber || ""}
                    </div>
                )}
                {data.RecPositionName && (
                    <div>
                        {Config.lang("Vi_tri_tuyen_dung")}: {data.RecPositionName || ""}
                    </div>
                )}
                {data.VoucherDate && (
                    <div>
                        {Config.lang("Ngay_de_xuat")}: {moment.utc(data.VoucherDate).format("DD/MM/YYYY") || ""}
                    </div>
                )}
                {data.ApproverName && (
                    <div>
                        {Config.lang("Nguoi_duyet")}: {data.ApproverName || ""} - {data.DepartmentIDApp || ""} - {moment.utc(data.AppDate).format("DD/MM/YYYY kk:mm:ss") || ""}
                    </div>
                )}
                {data.AppNote && (
                    <div>
                        {Config.lang("Ghi_chu_duyet")}: {data.AppNote || ""}
                    </div>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    redirectScreen = (mode, e = "") => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2002",
            state: {
                mode: mode,
                ...(e && e.data ? { TransID: e.data.TransID, AppStatusID: e.data.AppStatusID } : {})
            }
        });
    };

    onDelete = (data) => {
        const { TransID } = data;
        const param = {
            Language: Config.language || '84',
            DivisionID: Config.getDivisionID(),
            TransID: TransID,
        };

        Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_xoa?"),
            () => {
                this.props.w25f2000Actions.deleteDataGrid(param, (error) => {
                    if (error) {
                        Config.popup.show('ERROR', error);
                        return false;
                    }
                    else {
                        Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                        this.loadDataGrid();
                    }
                });
            });

    };

    onEdit = (e) => {
        this.redirectScreen("edit", e);
    };

    onAdd = () => {
        this.redirectScreen("add");
    };

    renderAction = e => {
        const { iPermission } = this.state;
        const { data } = e.row;
        const allowView = (iPermission > 0);
        const allowDelete = (iPermission > 3) && (Number(data.AppStatusID) === 0);
        return (
            <div className={"display_row align-center"}>
                <Tooltip title={Config.lang("Lich_su_duyet")} >
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"history"}
                        onClick={() => this.onHistoryApproval(e, data)}
                    />
                </Tooltip>
                <Tooltip title={Config.lang("Xem")} >
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"view"}
                        disabled={!allowView}
                        onClick={() => this.onEdit(e)}
                    />
                </Tooltip>
                <Tooltip title={Config.lang("Xoa")} >
                    <ButtonIcon
                        circular
                        viewBox
                        viewType={"text"}
                        name={"Delete"}
                        disabled={!allowDelete}
                        onClick={() => this.onDelete(data)}
                    />
                </Tooltip>
            </div>
        );
    };

    render() {
        const { getDataGrid } = this.props;
        const { skip, limit } = this.filter;
        const { gridLoading, iPermission, showW84F3005Popup,  w84F3005PopupData = {} } = this.state;
        return (
            <>
                <W84F3005 open={showW84F3005Popup} onClose={() => this.setState({showW84F3005Popup: false})}
                          FormID={"W25F2000"} TransID={w84F3005PopupData.TransID}/>
                <ActionToolbar title={Config.lang("De_xuat_tuyen_dung")}>
                    <Button
                        disabled={!(iPermission > 1)}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"AddCircle"}
                        viewType={"filled"}
                        label={Config.lang("Them")}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={getDataGrid && getDataGrid.rows ? getDataGrid.rows : []}
                    keyExpr={"TransID"}
                    onDbCellClick={e => this.redirectScreen("view", e)}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    rowAlternationEnabled={false}
                    noDataText={Config.lang("No_data")}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={getDataGrid && Number(getDataGrid.total) ? Number(getDataGrid.total) : 0}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} minWidth={150} maxWidth={200} />
                    <Column cellRender={this.renderAction} width={150} />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getDataGrid: state.W25F2000.getDataGrid,
            getCboAppStatus: state.W25F2000.getCboAppStatus,
        }),
        dispatch => ({
            w25f2000Actions: bindActionCreators(W25F2000Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W25F2000);
